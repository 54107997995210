import reject from "../Components/svg/reject.svg";
import save from "../Components/svg/save.svg";
import share from "../Components/svg/share.svg";

function Recs(props) {
  return props.rec.content.length !== 0 ? (
    props.rec.content.map((item, index) => (
      <div
        className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-6 text-black bg-gray-200 rounded-lg mx-[7%] px-4 py-2 border-b border-grey-300 hover:bg-grey-lighter h-full"
        key={index}
        name="rec_card"
      >
        {item.photos.length !== 0 && (
          <div className="col-span-2">
            <div>
              <img
                src={item.photos[0].file}
                className="object-scale-down h-full w-full drop-shadow-md rounded-md"
                alt="restaurant"
              />
            </div>
          </div>
        )}

        <div
          className={
            "md-5 md:mt-0 md:ml-3 h-full " +
            (item.photos.length === 0 ? "col-span-6" : "col-span-4")
          }
        >
          <h3 className="text-lg font-bold mb-2 mt-2 md:mt-0">{item.name}</h3>
          <div className="text-xs mb-4">
            <p>{item.summary.split(";")[0].replaceAll("'", "")}</p>
            <ul className="list-disc mt-3 ml-6">
              {item.summary.split(";").length !== 0 && (
                <>
                  <li key={0}>
                    {item.summary.split(";")[1].replaceAll("'", "")}
                  </li>
                  <li key={1}>
                    {item.summary.split(";")[2].replaceAll("'", "")}
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="flex items-center justify-between">
            {item.links.length !== 0 && (
              <button className="bg-blue-500 hover:bg-blue-700 font-poppins text-white font-bold text-xxs sm:py-2 p-1 sm:px-4 rounded">
                <a
                  href={
                    item.links.filter((item) => item.name === "google_maps")[0]
                      .url
                  }
                >
                  Google Maps
                </a>
              </button>
            )}
            <div className="flex gap-4">
              <img src={save} alt="save" />
              <img src={share} alt="share" />
              <img src={reject} alt="reject" />
            </div>
          </div>
        </div>
      </div>
    ))
  ) : (
    <></>
  );
}

export default Recs;
