export async function chatAPI(question, tags) {
  try {
    const reqBody =
      tags.length === 0
        ? { question: question }
        : { question: question, tags: tags.toString() };
    const res = await fetch("https://api.braidsocial.io/api/v1/chat", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}
