import { Outlet } from "react-router-dom";
function Main({ children }) {
  return (
    <>
      {/* <Header /> */}
      <Outlet />
    </>
  );
}

export default Main;
