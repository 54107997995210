import { useState, useRef, useEffect } from "react";
import { chatAPI } from "../Api/api";
import Recs from "../Components/Recs";
import Message from "../Components/Message";
import { ReactComponent as SvgIcon } from "../Components/svg/send.svg";

function Dashboard() {
  const inputRef = useRef(null);
  const sendBtnRef = useRef(null);
  const chatContentRef = useRef(null);
  const chatKeyBarRef = useRef(null);
  const sendBtnSpanRef = useRef(null);
  const DefaultMessages = [
    "I'm ready for my recs!",
    "Here are some places that we think you'll enjoy!",
    "Coming right up! Anything you want to add to your request?",
    "Sorry, please try again!",
  ];
  const grayColor = "#979797";
  const blueColor = "#0ea5e9";

  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [TagList, setTagList] = useState([]);
  const [SelectedTagList, setSelectedTagList] = useState([]);
  const [marginBottom, setMarginBottom] = useState(0);
  const [recsData, setrecsData] = useState([]);
  let containerElement = document.getElementById("container-chat");

  const mobileCheck = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent,
      )
    )
      return true;
    else return false;
  };

  const handleTextChange = (event) => {
    setInputText(event.target.value);
    // adjustInputHeight();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
    return false;
  };

  const adjustInputHeight = () => {
    inputRef.current.style.height = "auto";
    inputRef.current.style.height = `${inputRef.current.scrollHeight + 2}px`;
    sendBtnSpanRef.current.style.marginTop = `${
      inputRef.current.scrollHeight - 40
    }px`;
  };

  useEffect(() => {
    sendBtnRef.current.style.fill =
      inputRef.current.value === "" ? grayColor : blueColor;
    inputRef.current.disabled = isFetching;
  });

  useEffect(() => {
    inputRef.current.addEventListener("focusin", (eve) => {
      sendBtnRef.current.style.fill =
        inputRef.current.value === "" ? grayColor : blueColor;
      if (
        messages.length === 0 &&
        chatContentRef.current !== null &&
        mobileCheck() === true
      ) {
        chatContentRef.current.style.top = "auto";
        chatContentRef.current.style.bottom = "150px";
      }
    });
    inputRef.current.addEventListener("focusout", () => {
      if (
        messages.length === 0 &&
        chatContentRef.current !== null &&
        mobileCheck() === true
      ) {
        chatContentRef.current.style.bottom = "auto";
        chatContentRef.current.style.top = "48px";
      }
    });
  }, []);

  const handleSubmit = () => {
    containerElement.scrollTop = 0;
    if (inputText === DefaultMessages[0] && SelectedTagList.length === 0) {
      setMessages((messages) =>
        messages.concat([
          {
            content: DefaultMessages[1],
            role: "assistant",
          },
          { content: recsData, role: "recs" },
        ]),
      );
      setrecsData([]);
      setTagList([]);
    } else {
      setQuery(inputText);
      if (SelectedTagList.length !== 0) setTagList([]);
    }
    setInputText("");
  };

  const setTag = (tag) => {
    if (SelectedTagList.includes(tag)) {
      setSelectedTagList(SelectedTagList.filter((item) => item !== tag));
    } else {
      setSelectedTagList([...SelectedTagList, tag]);
    }
  };

  useEffect(() => {
    if (containerElement)
      containerElement.scrollTop = containerElement.scrollHeight;

    if (query) {
      var queryTemp = query;
      if (query === DefaultMessages[0])
        queryTemp = `${
          messages[messages.length - 2].content
        } Here are more details. ${SelectedTagList.toString()}`;
      setMessages((messages) =>
        messages.concat({ content: queryTemp, role: "user" }),
      );
      setIsFetching(true);
    }
  }, [query]);

  useEffect(() => {
    if (isFetching) {
      document.getElementById("loading").style.display = "flex";
      if (containerElement)
        containerElement.scrollTop = containerElement.scrollHeight;
      var question = query;
      if (query === DefaultMessages[0] && SelectedTagList !== 0)
        question = messages[messages.length - 3].content;
      chatAPI(question, SelectedTagList)
        .then((res) => {
          document.getElementById("loading").style.display = "none";
          if (res.count === 0) {
            setMessages((messages) =>
              messages.concat({
                content: DefaultMessages[3],
                role: "assistant",
              }),
            );
            setMarginBottom(0);
            setSelectedTagList([]);
            setTagList([]);
            setIsFetching(false);
          } else if (SelectedTagList.length === 0) {
            setMessages((messages) =>
              messages.concat({
                content: DefaultMessages[2],
                role: "assistant",
              }),
            );
            setrecsData(res.results);
            setTagList(res.tags.map((item) => item[0]));
            setMarginBottom(
              chatKeyBarRef.current ? chatKeyBarRef.current.offsetHeight : 0,
            );
            setInputText(DefaultMessages[0]);
            setIsFetching(false);
          } else {
            setMessages((messages) =>
              messages.concat([
                {
                  content: DefaultMessages[1],
                  role: "assistant",
                },
                { content: res.results, role: "recs" },
              ]),
            );
            setMarginBottom(0);
            setSelectedTagList([]);
            setTagList([]);
            setIsFetching(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [isFetching]);

  useEffect(() => {
    setMarginBottom(
      chatKeyBarRef.current ? chatKeyBarRef.current.offsetHeight : 0,
    );
    if (messages.length < 3) window.scrollTo(0, 0);

    if (containerElement && messages.slice(-1)[0]) {
      if (messages.slice(-1)[0]["role"] === "recs") {
        containerElement.scrollTop =
          containerElement.scrollHeight - window.innerHeight - 100;
      } else containerElement.scrollTop = containerElement.scrollHeight;
    }
    document.getElementById("container-chat").click();
    inputRef.current.blur();
    sendBtnRef.current.style.fill =
      SelectedTagList.length !== 0 ? blueColor : grayColor;
  }, [TagList, isFetching, SelectedTagList, marginBottom]);

  return (
    <>
      {messages.length !== 0 && (
        <>
          <div id="toolbar-wrap">
            <div
              id="toolbar"
              className="w-full flex justify-center items-center fixed z-10 top-0 text-white text-center font-poppins sm:h-20 h-12 py-4 header-blue text-base font-bold sm:text-2xl blue-header"
            >
              Braid Miami
            </div>
            <div className="w-full sm:h-20 h-12" />
          </div>
        </>
      )}
      <div
        id="container-chat"
        className="w-full h-[calc(100vh-151px)] sm:h-[calc(100vh-171px)] items-start justify-center overflow-y-auto font-poppins scroll-smooth"
      >
        {messages.length !== 0 && (
          <>
            <div
              className="grid mt-4 mb-4 gap-y-4 overflow-y-auto"
              style={{ marginBottom: marginBottom }}
            >
              {messages.map((item, index) =>
                item.role === "recs" ? (
                  <Recs rec={item} key={index} />
                ) : (
                  <Message message={item} key={index} />
                ),
              )}
            </div>
            <div
              className="flex items-start gap-x-3 mt-4 bg-gray-200 text-black rounded-lg mx-[7%] p-3 border-b border-grey"
              id="loading"
              style={{ display: "none" }}
            >
              <div className="bot-text m-2">
                <div className="loading-spinner">
                  <div className="arc arc1"></div>
                  <div className="arc arc2"></div>
                  <div className="arc arc3"></div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* Additaional key words bar*/}
      {TagList.length !== 0 && (
        <div
          className="fixed w-full bottom-[101px] sm:bottom-[89px] flex flex-wrap p-2 gap-2 justify-center"
          ref={chatKeyBarRef}
        >
          {TagList.map((tag, index) => (
            <button
              className={`rounded-lg focus:rounded-lg p-2 text-sm hover:text-bold ${
                SelectedTagList.includes(tag)
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              key={index}
              value={tag}
              id={`${index}_sgbtn`}
              onClick={(e) => setTag(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
      )}

      {/* Footer Input chat */}
      <div
        className="fixed bottom-0 w-full bg-gray-100 px-4 pt-4 pb-1"
        id="mo_footer"
      >
        <form>
          <label className="relative block items-center">
            {messages.length === 0 && (
              <div
                id="apple"
                className="fixed top-12 grid gap-y-4 text-white text-left mx-[10%]"
                ref={chatContentRef}
                style={{ marginBottom: marginBottom }}
              >
                <div className="text-base font-bold">Braid Miami</div>
                <div className="text-3xl font-bold">
                  Good food recs - every time.
                </div>
                <div className="text-base-18 font-medium">
                  Just tell us what you're in the mood for
                </div>
              </div>
            )}
            <textarea
              className="w-full resize-none bg-white text-base placeholder:font-italitc border border-slate-400 drop-shadow-md focus:outline-none focus:border-sky-500 rounded-md py-2 pl-3 pr-12"
              placeholder="Best new restaurants in Miami"
              rows={1}
              value={inputText}
              onChange={handleTextChange}
              onKeyDown={handleKeyDown}
              type="text"
              ref={inputRef}
            />
            <span
              className="absolute inset-y-[11px] right-[14px]"
              onClick={handleSubmit}
              ref={sendBtnSpanRef}
            >
              <SvgIcon
                style={{
                  width: 20,
                  height: 20,
                  verticalAlign: "middle",
                  fill: grayColor,
                  overflow: "hidden",
                }}
                ref={sendBtnRef}
              ></SvgIcon>
            </span>
          </label>
          <div className="text-gray-400 text-center my-1 mx-[15%] text-base-8">
            We searched and aggregated the best sources across the web so you
            don't have to!
          </div>
        </form>
      </div>
    </>
  );
}

export default Dashboard;
