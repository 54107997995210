import userAvatar from "../Components/svg/user.svg";
import gptAvatar from "../Components/svg/gpt.svg";

function Message(props) {
  return props.message.role === "user" ? (
    <div className="flex items-start gap-x-3 bg-white text-black rounded-lg mx-[7%] p-3 border-b border-grey">
      <img src={userAvatar} alt="user" />
      <p className="text-left">{props.message.content}</p>
    </div>
  ) : (
    <div className="flex items-start gap-x-3 bg-gray-200 text-black rounded-lg mx-[7%] p-3 border-b border-grey">
      <img src={gptAvatar} alt="gpt" />
      <p className="text-left">{props.message.content}</p>
    </div>
  );
}

export default Message;
